import { useContext, useEffect, useState } from 'react';
import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem,
  Collapse
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import GroupIcon from '@mui/icons-material/Group';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CreditCard from '@mui/icons-material/CreditCard';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import MapIcon from '@mui/icons-material/Map';
import ViewListIcon from '@mui/icons-material/ViewList';
import EngineeringIcon from '@mui/icons-material/Engineering';
import DescriptionIcon from '@mui/icons-material/Description';
import TaskIcon from '@mui/icons-material/Task';
import SecurityIcon from '@mui/icons-material/Security';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import { AuthContext } from 'src/contexts/Auth/AuthContext';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(0.2)};
    background: red

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
      background: red
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
      background: red
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
    'transform',
    'opacity'
  ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
)

function SidebarMenu() {
  const { infoAcesso } = useContext(AuthContext)
  const { closeSidebar } = useContext(SidebarContext);

  const [openListConfiguracao, setOpenListConfiguracao] = useState(false);

  const handleClickListConfiguracao = () => {
    setOpenListConfiguracao(!openListConfiguracao);
  };

  const [usuario, setUsuario] = useState('')

  useEffect(() => {
    let infoUsuario = localStorage.getItem('authToken');
    setUsuario(JSON.parse(infoUsuario))
  }, [])

  return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/inicio"
                  startIcon={<HomeIcon />}
                >
                  Início
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/gestor"
                  startIcon={<GroupIcon />}
                >
                  Gestores
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/processos"
                  startIcon={<DescriptionIcon />}
                >
                  Processos
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/searchDocument"
                  startIcon={<SearchIcon />}
                >
                  Pesquisar CNPJ / CPF
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        {/* <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/cliente"
                  startIcon={<GroupIcon />}
                >
                  Clientes
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}

        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              {/* <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/plano"
                  startIcon={<AttachMoneyIcon />}
                >
                  Planos
                </Button>
              </ListItem> */}
            </List>
          </SubMenuWrapper>
        </List>

         <List component="div">
          <SubMenuWrapper>
            <List component="div">
              {/* <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/vendaplano"
                  startIcon={<CreditCard/>}
                >
                  Vendas de Plano
                </Button>
              </ListItem> */}
            </List>
          </SubMenuWrapper>
        </List>

        {/* <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/relatorio"
                  startIcon={<DescriptionIcon />}
                >
                  Relatórios
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List> */}



      </MenuWrapper >
    </>
  );
}

export default SidebarMenu;
