import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RequireAuth } from './contexts/Auth/RequireAuth';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';


//let infoUsuario = localStorage.getItem('authToken');
// const p = JSON.parse(infoUsuario).p

const Loader = (Component) => (props) =>
(
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const UserProfile = Loader(
  lazy(() => import('src/pages/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/pages/Users/settings'))
);

const Login = Loader(
  lazy(() => import('src/pages/Login')));

const PoliticaPrivacidade = Loader(
  lazy(() => import('src/pages/PoliticaPrivacidade')));

const DetalhesApp = Loader(
  lazy(() => import('src/pages/DetalhesApp')));

const Home = Loader(
  lazy(() => import('src/pages/Home')));

const Gestor = Loader(
  lazy(() => import('src/pages/Gestor'))
);
const GestorForm = Loader(
  lazy(() => import('src/pages/Gestor/form'))
);
const GestorFormSenha = Loader(
  lazy(() => import('src/pages/Gestor/formSenha'))
);

const Cliente = Loader(
  lazy(() => import('src/pages/Cliente'))
);
const ClienteForm = Loader(
  lazy(() => import('src/pages/Cliente/form'))
);
const ClienteFormSenha = Loader(
  lazy(() => import('src/pages/Cliente/formSenha'))
);

const Status404 = Loader(
  lazy(() => import('src/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/pages/Status/Status500'))
);

const StatusMaintenance = Loader(
  lazy(() => import('src/pages/Status/Maintenance'))
);

const Processos = Loader(
  lazy(() => import('src/pages/Processos'))
);
const ProcessosDetails = Loader(
  lazy(() => import('src/pages/Processos/Details'))
);
const SearchDocument = Loader(
  lazy(() => import('src/pages/SearchDocument'))
);

const routes = [
  {
    path: 'inicio',
    element: <RequireAuth><SidebarLayout /></RequireAuth>,
    children: [
      {
        path: '',
        element: <RequireAuth><Home /></RequireAuth>
      }
    ]
  },
  {
    path: 'gestor',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <RequireAuth><Gestor /></RequireAuth>
      },
      {
        path: 'editar/:id',
        element: <RequireAuth><GestorForm /></RequireAuth>
      },
      {
        path: 'adicionar',
        element: <RequireAuth><GestorForm /></RequireAuth>
      },
      {
        path: 'alterarsenha/:id',
        element: <RequireAuth><GestorFormSenha /></RequireAuth>
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <RequireAuth><Navigate to="details" replace /></RequireAuth>
          },
          {
            path: 'details',
            element: <RequireAuth><UserProfile /></RequireAuth>
          },
          {
            path: 'settings',
            element: <RequireAuth><UserSettings /></RequireAuth>
          }
        ]
      },
    ]
  },
  {
    path: 'processos',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <RequireAuth><Processos /></RequireAuth>
      },
      {
        path: 'Details/:numero',
        element: <RequireAuth><ProcessosDetails /></RequireAuth>
      },
    ]
  },
  {
    path: 'searchDocument',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <RequireAuth><SearchDocument /></RequireAuth>
      },
      {
        path: 'Details/:numero',
        element: <RequireAuth><ProcessosDetails /></RequireAuth>
      },
    ]
  },
  
  

  {
    path: 'cliente',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <RequireAuth><Cliente /></RequireAuth>
      },
      {
        path: 'editar/:id',
        element: <RequireAuth><ClienteForm /></RequireAuth>
      },
      {
        path: 'adicionar',
        element: <RequireAuth><ClienteForm /></RequireAuth>
      },
      {
        path: 'alterarsenha/:id',
        element: <RequireAuth><ClienteFormSenha /></RequireAuth>
      }
    ]
  },
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: 'login',
        element: <Navigate to="/" replace />
      },
      {
        path: '/politicaprivacidade',
        element: <PoliticaPrivacidade />
      },
      {
        path: '/detalhesapp',
        element: <DetalhesApp />
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  }
];

export default routes;